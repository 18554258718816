import {
  Component,
  OnInit
} from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login',
  template: ''
})
export class LoginComponent implements OnInit {
  constructor(private authService: MsalService) { }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();
  }
}
