import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';


const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/alla.module')
        .then(m => m.ALLAModule),
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? 'enabledNonBlocking'
        : 'disabled', // Set to enabledBlocking to use Angular Universal
  }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
