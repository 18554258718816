<app-header></app-header>

<div>
  <nav mat-tab-nav-bar>
      <a mat-tab-link routerLink="/leads" routerLinkActive="active-link" class="btn btn-link">Leads</a>
      <a mat-tab-link routerLink="/leads-history" routerLinkActive="active-link" class="btn btn-link">Leads History</a>
      <a mat-tab-link routerLink="/rules" routerLinkActive="active-link" class="btn btn-link">Rules</a>
  </nav>
</div>

<div class="main-container">
  <router-outlet></router-outlet>
  <app-redirect></app-redirect>
</div>

<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<notifier-container></notifier-container>